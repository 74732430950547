import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore/firestore';
import { AngularFirestoreCollection } from '@angular/fire/firestore/public_api';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'xibalbaweb';
  private itemsCollection: AngularFirestoreCollection<IItems>;
  public items: Observable<IItems[]>;
  constructor(private afs: AngularFirestore) {
    /* this.itemsCollection = afs.collection<IItems>('items');
    this.items = this.itemsCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as IItems;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
    this.addItem({id: 0, value: 'asdfsdfs'}); */
  }
  addItem(item: IItems) {
    this.itemsCollection.add(item);
  }
}
interface IItems {
  id: number;
  value: string;
}
